import React, { useState, useEffect, useRef } from 'react';
import styles from './SelectSearch.module.css';

interface Option {
    value: string;
    label: string;
}

interface SearchableSelectProps {
    name: string;
    options: Option[];
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className: string
    resetInput: boolean
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({ name, options, value, onChange, placeholder, className, resetInput }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setFilteredOptions(
            options.filter(option => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [searchTerm, options]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        const selectedOption = options.find(option => option.value === value);
        if (selectedOption) {
            setSearchTerm(selectedOption.label);
        }
    }, [value, options]);
    useEffect(() => {
        if (resetInput) {
            setSearchTerm('');
        }
    }, [resetInput]);
    const handleSelect = (option: Option) => {
        onChange({ target: { name, value: option.value } } as React.ChangeEvent<HTMLInputElement>);
        setSearchTerm(option.label);
        setIsOpen(false);
    };
    useEffect(() => {
        if (inputRef.current) {
            const handleInputEvent = () => {
                const inputValue = inputRef.current!.value;
                setSearchTerm(inputValue);

                const selectedOption = options.find(option => option.label === inputValue);
                if (selectedOption) {
                    onChange({ target: { name, value: selectedOption.value } } as React.ChangeEvent<HTMLInputElement>);
                }
            };

            const observer = new MutationObserver(handleInputEvent);
            observer.observe(inputRef.current, {
                attributes: true,
                attributeFilter: ['value']
            });

            inputRef.current.addEventListener('input', handleInputEvent);

            return () => {
                if (inputRef.current) {
                    inputRef.current.removeEventListener('input', handleInputEvent);
                    observer.disconnect();
                }
            };
        }
    }, [options, onChange, name]);

    return (
        <div className={styles.searchableSelect} ref={containerRef}>
            <input
                type="text"
                ref={inputRef}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onClick={() => setIsOpen(true)}
                placeholder={placeholder}
                className={className}
            />
            {isOpen && (
                <ul className={styles.options}>
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map(option => (
                            <li key={option.value} onClick={() => handleSelect(option)}>
                                {option.label}
                            </li>
                        ))
                    ) : (
                        <li className={styles.noOptions}>No options found</li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default SearchableSelect;
