import {useEffect, useState} from "react";
import dynamic from 'next/dynamic';
import { useGlobalContext } from "../../lib/context";
import { getCookie, setCookie } from '../../lib/helpers/Cookie';
import TagManager from 'react-gtm-module';
const DynamicFloatingWidget = dynamic(() => import('../shared/floatingWidget').then((mod) => mod.FloatingWidget),{
  suspense:true,
  ssr:false
})
const DynamicExitIntentPopup = dynamic(() => import('../shared/exitIntentPopUp/ExitIntentPopup').then((mod) => mod.ExitIntentPopup),{
  suspense:true,
  ssr:false
})

type props={
    utmSource: string;
}
export default function LayoutTracking({utmSource}:props){
    const {props:{isHideFloatingWidget, countryCode,pageURL}}=useGlobalContext();
    const [isDisplayed, setIsDisplayed] = useState(false);
    const [isDisplayedExitIntentPopup, setIsDisplayedExitIntentPopup] = useState(false);
    useEffect(() => {
      if(process.env.GTM_ID)
        TagManager.initialize({ gtmId : process.env.GTM_ID||"" });
  }, []);

    useEffect(() => {  
        const cookieUtmToken = getCookie("UTM_TOKEN");
        if(utmSource && (!cookieUtmToken || cookieUtmToken !== utmSource))
        {
          const expiryDays=process.env.COOKIEE_EXPIRY_DAYS && parseInt(process.env.COOKIEE_EXPIRY_DAYS) || 2;
          setCookie("UTM_TOKEN",utmSource,expiryDays);
        }
        setTimeout(() => {
          setIsDisplayed(true);
        }, 8000);
        setTimeout(() => {
          if(sessionStorage.getItem('isIntentPopup') !== 'false' && !isHideFloatingWidget){
            setIsDisplayedExitIntentPopup(true);
          }
        }, 10000);
      }, [countryCode]);
    return (<>
     {!isHideFloatingWidget && isDisplayed && <DynamicFloatingWidget/>}
     {(!isHideFloatingWidget || pageURL.includes("/trends")) && isDisplayedExitIntentPopup  && <DynamicExitIntentPopup/>}
    </>)
}