import NextLink from "next/link";

export { Link };

function Link({ href, children, ...props }:{
    [x:string]:any,
    href:string,
    children:any
}):JSX.Element {
  return (
    <NextLink href={href} {...props} prefetch = {false} >
    {children}
  </NextLink>
  );
}