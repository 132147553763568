import { createContext, useContext } from 'react';
import {ILead} from '../../interfaces';

export type LeadData = {
  lead: ILead;
  apiError:string;
  sendOtp:(data:ILead|null)=>void;
  resendOtp:()=>void;
  changeNumber:()=>void;
  submit:(otp:number)=>void;
  clear:()=>void;
  sourceType:string
}
export const LeadContext = createContext<Partial<LeadData>>({})
export const useLeadContext = () => useContext(LeadContext)