import React from "react";

export const Select = ({ value, ...props }:{
    [x:string]:any,
    value:string
}) => {    
    return ( 
        <select
              name={props.name}
              id={props.id}
              disabled={props.disabled}
              className = { props.className }
              value={value}
              onChange={props.onChange}
              {...props}
          >
              {props.children}
          </select>
    );
};
