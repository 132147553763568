import { createContext, useContext } from 'react';
export type DesignCategory = {
  categorySlug:string;
  isMobileFilter:boolean;
  toggleMobileFilter:(isFilter:boolean)=>void;
  attributeSlug:string;
  changeAttributeSlug:(slug:string)=>void;
}

export const DesignCatContext = createContext<Partial<DesignCategory>>({});
export const useDesignCatContext = () => useContext(DesignCatContext)